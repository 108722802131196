import React, { useState, useEffect } from "react";
import axios from "axios";
import Api from "../../../services/api";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { API_ROOT, FRONT_HOST } from "../../../services/api-config";
import Collapsible from "react-collapsible";
import chevronUp from "../../../assets/img/ChevronUp.svg";
import chevronDown from "../../../assets/img/Chevrondown.svg";
import {
  useStripe,
  useElements,
  CardElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import "./Budget.css";
import ChangeCard from "./ChangeCard";
import UpdateCard from "./UpdateCard";
import DefaultCard from "./DefaultCard";
const useStyles = makeStyles({
  root: {
    maxWidth: 500,
    margin: "0vh auto",
    boxShadow: "0px 0px 16px rgb(0 0 0 / 29%)",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-start",
  },
  div: {
    display: "flex",
    flexDirection: "row",
    alignContent: "flex-start",
    justifyContent: "space-between",
  },
  button: {
    margin: "2em auto 1em",
  },
});

let api;

const HomePage = ({
  authToken,
  userEmail,
  userline1,
  userstate,
  stripeCustomerId,
  userCity,
  userPostalCode,
  renderFlag,
  optionRef,
  existingexpiry,
  handleCountryChange,
  userCountryIdValue,
  usercountry,
  history,
  countriesList,
  userName1,
  unitAmount,
  priceId,
}) => {
  const [email, setEmail] = useState(userEmail);
  const [city, setCity] = useState(userCity);
  const [currency] = useState("USD");
  const [amount, setAmount] = useState("");
  const [line1, setLine1] = useState(userline1);
  const [country, setCountry] = useState(usercountry);
  const [updateModalOpen] = useState(false);
  const [countryIdValue, setCountryIdValue] = useState(userCountryIdValue);
  const [changeCard, setChangeCard] = useState(false);
  const [updateCard, setUpdateCard] = useState(false);
  const [editCard, setEditCard] = useState(false);
  const [paymentSource] = useState(history.location.state.paymentSource);
  const [offTakeData] = useState(history.location.state.offTakeData);
  const [paymentReturnTarget] = useState(
    history.location.state.paymentReturnTarget
  );
  const [paymentReturnState] = useState(
    history.location.state.paymentReturnState
  );
  const [state, setState] = useState(userstate);
  const [userName, setUserName] = useState(userName1);
  const [postal_code, setPostalCode] = useState(userPostalCode);
  const [expiry, setExpiry] = useState(existingexpiry);
  const [newexpiry, setNewExpiry] = useState("");
  const [depositLoading, setDepositLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [cvc, setCvc] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [qty, setQty] = useState();
  const stripe = useStripe();
  const elements = useElements();
  useEffect(() => {
    api = new Api();
    setEmail(userEmail);
    setUserName(userName1);
    setLine1(userline1);
    setCountry(usercountry);
    setCountryIdValue(userCountryIdValue);
    setState(userstate);
    setExpiry(existingexpiry);
    setCity(userCity);
    setPostalCode(userPostalCode);
  }, [
    userEmail,
    userCity,
    userline1,
    userstate,
    usercountry,
    userCountryIdValue,
    userName1,
    existingexpiry,
    userPostalCode,
  ]);
  const classes = useStyles();
  axios.defaults.headers.common["Authorization"] = authToken;

  const upgradeBudget = async () => {
    try {
      if (qty > 0 && unitAmount > 0) {
        setDepositLoading(true);
        let api = "";
        let requestObj = {};
        if (paymentSource === "DEC") {
          api = `${API_ROOT}/miner/decStripePayment`;
          requestObj = {
            decPrice: priceId,
            tokens: qty,
            unitPrice: unitAmount,
            domainURL: FRONT_HOST,
            purposeId: offTakeData ? offTakeData.purposeId : undefined, // e.g, listing ID for offtake
            energyAmount: offTakeData ? offTakeData.energyAmount : undefined,
            duration: offTakeData ? offTakeData.duration : undefined,
          };
        } else if (paymentSource === "budget") {
          api = `${API_ROOT}/miner/create-checkout-session`;
          requestObj = {
            unitPrice: unitAmount,
            qty: qty,
            price: priceId,
            amount: amount,
            domainURL: FRONT_HOST,
          };
        }
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: authToken,
          },
        };
        const res = await axios.post(api, requestObj, config);

        setDepositLoading(false);
        if (res.status === 200) {
          toast.success(res.data.message);
          // Initiate payment dialog
          window.open(res.data.data.url, "_self");
        } else {
          toast.error(res.data.message);
        }
      } else {
        toast.error("Please make sure the quantity is greater than 0!");
      }
    } catch (e) {
      console.log(e);
      toast.error(e.response.data && e.response.data.data);
      setDepositLoading(false);
    }
  };

  const updatePaymentMethod = async () => {
    try {
      setSubmitLoading(true);
      let month, year;
      if (expiry) {
        let expiryArr = expiry.split("/");
        month = expiryArr[0].trim();
        year = expiryArr[1].trim();
      }
      if (expiry) {
        const date = new Date();
        let stringYear = date.getFullYear().toString();
        stringYear = stringYear.slice(-2);
        const currentYear = Number(stringYear);

        if (month && year && Number(year) >= currentYear) {
          if (
            country !== "Select Country" &&
            email &&
            line1 &&
            countryIdValue &&
            state &&
            city
          ) {
            const userResponse = await api.create("miner/updatePaymentMethod", {
              type: paymentSource,
              email: email,
              line1: line1,
              userName: userName,
              country: countryIdValue,
              state: state,
              city: city,
              expi_month: month,
              expi_year: year,
              postal_code: postal_code,
            });
            setSubmitLoading(false);
            if (userResponse.code === 200) {
              toast.success(userResponse.message);
              setUpdateCard(false);
              setChangeCard(false);
            } else {
              toast.error("Failed to save the details!");
            }
          } else {
            toast.error("Please fill all the Details!");
            setSubmitLoading(false);
          }
        }
      } else {
        toast.error("Please fill all the Details!");
        setSubmitLoading(false);
      }
    } catch (e) {
      console.log(e);
      setUpdateCard(false);
      setChangeCard(false);
      setSubmitLoading(false);
    }
  };

  const toggleCardDetails = () => {
    if (editCard === true) {
      setEditCard(false);
    } else if (editCard === false) {
      setEditCard(true);
    }
  };
  const handleSubmitPay = async (event) => {
    try {
      if (qty > 0 && unitAmount > 0) {
        setDepositLoading(true);
        let api = "";
        if (!stripe || !elements) {
          return;
        }
        var result = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement),
          cardNumber: elements.getElement(CardExpiryElement),
          billing_details: {
            email: email,
            name: userName,
            address: {
              country: countryIdValue,
              city: city,
              state: state,
              line1: line1,
            },
          },
        });
        const postalCode =
          result &&
          result.paymentMethod &&
          result.paymentMethod.billing_details &&
          result.paymentMethod.billing_details.address &&
          result.paymentMethod.billing_details.address.postal_code;

        const exp_year =
          result &&
          result.paymentMethod &&
          result.paymentMethod.card &&
          result.paymentMethod.card.exp_year;

        if (result.error) {
          console.log(result.error.message);
          toast.error(result.error.message);
          setDepositLoading(false);
          return;
        }
        let requestObj = {
          paymentMethod: result.paymentMethod.id,
          email: email,
          userName: userName,
          line1: line1,
          country: countryIdValue,
          city: city,
          state: state,
          unitPrice: unitAmount,
          tokens: qty,
          postalCode: postalCode,
          domainURL: FRONT_HOST,
        };
        if (paymentSource === "DEC") {
          api = `${API_ROOT}/miner/decStripePayment`;
          requestObj.tokens = qty;
          requestObj.decPrice = priceId;
          requestObj.purposeId = offTakeData
            ? offTakeData.purposeId
            : undefined; // e.g, listing ID for offtake
          requestObj.energyAmount = offTakeData
            ? offTakeData.energyAmount
            : undefined;
          requestObj.duration = offTakeData ? offTakeData.duration : undefined;
        } else if (paymentSource === "budget") {
          api = `${API_ROOT}/miner/create-checkout-session`;
          requestObj.qty = qty;
          requestObj.price = priceId;
          requestObj.amount = amount;
        }
        if (exp_year && exp_year) {
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: authToken,
            },
          };
          const res = await axios.post(api, requestObj, config);
          // eslint-disable-next-line camelcase

          if (res.status === 200) {
            setDepositLoading(false);
            toast.success(res.data.message);
            // Initiate payment dialog
            window.open(res.data.data.url, "_self");
          } else {
            toast.error(res.data.message);
          }
        } else {
          toast.error("Please fill the card details!");
          setDepositLoading(false);
        }
      } else {
        toast.error("Please make sure the quantity is greater than 0!");
      }
    } catch (e) {
      console.log(e);
      toast.error(e.response.data && e.response.data.data);
      setDepositLoading(false);
    }
  };

  const handleChangeCard = async () => {
    try {
      setSubmitLoading(true);
      let expiryArr = newexpiry.split("/");
      let month = expiryArr[0] ? expiryArr[0].trim() : "";
      let year = expiryArr[1] ? expiryArr[1].trim() : "";
      if (
        email &&
        userName &&
        line1 &&
        countryIdValue &&
        month &&
        cvc &&
        year &&
        city &&
        cardNumber &&
        state
      ) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: authToken,
          },
        };

        const res = await axios.post(
          `${API_ROOT}/miner/createPayment`,
          {
            email: email,
            name: userName,
            line1: line1,
            country: countryIdValue,
            expi_month: month,
            type: paymentSource,
            cvcReq: cvc,
            expi_year: year,
            city: city,
            postal_code: postal_code,
            num: cardNumber,
            state: state,
          },
          config
        );
        setSubmitLoading(false);
        if (res.status === 200) {
          toast.success(res.data.message);
          setChangeCard(false);
        } else {
          toast.error(res.data.message);
        }
      } else {
        setSubmitLoading(false);
        toast.error("Please fill all the details!");
      }
    } catch (e) {
      console.log(e);
      toast.error("Failed to save the details!");
      setSubmitLoading(false);
    }
  };
  return (
    <>
      <Card className={classes.root + " budget-background"}>
        <CardContent className={classes.content}>
          <div>
            {!renderFlag && (
              <div className="text-center mb-2 mt-2">
                <span id="loading" color="#047bf8" />
              </div>
            )}
          </div>
          {!changeCard && !updateCard && (
            <div className="row justify-content-start mb-3">
              <span
                className="ml-2"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  history.push(
                    paymentReturnTarget && paymentReturnTarget !== ""
                      ? paymentReturnTarget
                      : "/wallets"
                  );
                  if (paymentReturnState) {
                    history.push({
                      state: { paymentReturnState: paymentReturnState },
                    });
                  }
                }}
              >
                <i
                  className="fa fa-arrow-left standardColorIcon mr-4"
                  style={{ fontSize: "20px" }}
                ></i>
              </span>
            </div>
          )}
          {(changeCard || updateCard) && (
            <div className="row justify-content-start mb-3">
              <span
                className="ml-2"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setChangeCard(false);
                  setUpdateCard(false);
                  toggleCardDetails();
                }}
              >
                <i
                  className="fa fa-arrow-left standardColorIcon mr-4"
                  style={{ fontSize: "20px" }}
                ></i>
              </span>
            </div>
          )}
          {stripeCustomerId && !changeCard && !updateCard && (
            <div className="row align-items-start justify-content-start">
              <Collapsible
                className="text-bold billingCollap ml-2 mr-2"
                trigger={
                  <div
                    className="row p-2 justify-content-between"
                    onClick={() => {
                      toggleCardDetails();
                    }}
                  >
                    <span
                      className="text-bold text-black"
                      style={{ cursor: "pointer" }}
                    >
                      Edit Card
                    </span>
                    {!editCard && (
                      <img
                        className="questionColor"
                        src={chevronDown}
                        style={{ width: "20px", height: "20px" }}
                        alt=""
                      />
                    )}
                    {editCard && (
                      <img
                        className="questionColor"
                        src={chevronUp}
                        style={{ width: "20px", height: "20px" }}
                        alt=""
                      />
                    )}
                  </div>
                }
                containerElementProps={{
                  style: {
                    width: "100%",
                    border: "1px solid #DFE1E6",
                    borderRadius: "5px",
                    padding: "10px",
                    boxSizing: "border-box",
                  },
                }}
              >
                <div className="mt-2 form-group ">
                  <div
                    className="gray"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setUpdateCard(true);
                    }}
                  >
                    Update Existing Card
                  </div>
                  <div
                    className="gray"
                    style={{
                      color: "gray",
                      cursor: "pointer",
                      marginTop: "10px",
                    }}
                    onClick={() => {
                      setChangeCard(true);
                    }}
                  >
                    Add New Card
                  </div>
                </div>
              </Collapsible>
            </div>
          )}

          {!updateCard && !changeCard && (
            <DefaultCard
              optionRef={optionRef}
              countriesList={countriesList}
              handleCountryChange={handleCountryChange}
              userCountryIdValue={userCountryIdValue}
              usercountry={usercountry}
              stripeCustomerId={stripeCustomerId}
              setQty={setQty}
              upgradeBudget={upgradeBudget}
              setState={setState}
              setEmail={setEmail}
              setCity={setCity}
              setLine1={setLine1}
              qty={offTakeData ? offTakeData.tokenAmount : qty}
              isFixedOfftake={offTakeData ? true : false}
              handleSubmitPay={handleSubmitPay}
              amount={amount}
              setAmount={setAmount}
              email={email}
              unitAmount={unitAmount}
              city={city}
              currency={currency}
              line1={line1}
              country={country}
              updateModalOpen={updateModalOpen}
              countryIdValue={countryIdValue}
              changeCard={changeCard}
              updateCard={updateCard}
              editCard={editCard}
              paymentSource={paymentSource}
              state={state}
              userName={userName}
              postal_code={postal_code}
              expiry={expiry}
              newexpiry={newexpiry}
              depositLoading={depositLoading}
              submitLoading={submitLoading}
              cvc={cvc}
              cardNumber={cardNumber}
              postCode={postal_code}
            />
          )}
          {updateCard && (
            <UpdateCard
              optionRef={optionRef}
              countriesList={countriesList}
              handleCountryChange={handleCountryChange}
              setEmail={setEmail}
              setCity={setCity}
              setLine1={setLine1}
              email={email}
              city={city}
              currency={currency}
              line1={line1}
              country={country}
              countryIdValue={countryIdValue}
              state={state}
              userName={userName}
              postal_code={postal_code}
              setCardNumber={setCardNumber}
              expiry={expiry}
              setState={setState}
              setPostalCode={setPostalCode}
              setExpiry={setExpiry}
              newexpiry={newexpiry}
              submitLoading={submitLoading}
              cvc={cvc}
              updatePaymentMethod={updatePaymentMethod}
              cardNumber={cardNumber}
              postCode={postal_code}
            />
          )}
          {changeCard && (
            <ChangeCard
              optionRef={optionRef}
              countriesList={countriesList}
              handleCountryChange={handleCountryChange}
              setEmail={setEmail}
              setCity={setCity}
              setLine1={setLine1}
              email={email}
              city={city}
              currency={currency}
              line1={line1}
              country={country}
              countryIdValue={countryIdValue}
              state={state}
              userName={userName}
              postal_code={postal_code}
              expiry={expiry}
              setState={setState}
              setPostalCode={setPostalCode}
              setCardNumber={setCardNumber}
              setNewExpiry={setNewExpiry}
              setCvc={setCvc}
              setExpiry={setExpiry}
              newexpiry={newexpiry}
              submitLoading={submitLoading}
              handleChangeCard={handleChangeCard}
              cvc={cvc}
              cardNumber={cardNumber}
              postCode={postal_code}
            />
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default HomePage;
