import React from "react";
import Modal from "react-responsive-modal";

const ShowPowerInfo = (props) => {
  return (
    <Modal
      open={props.handleOpen}
      onClose={props.handleClose}
      center
      classNames={{
        closeIcon: "pull-right cursor-pointer",
        modal: "instructon-modal-container",
        margin: "5px",
      }}
    >
      <div>
        <br />
        <br />
        <br />
      </div>
      <div
        className="font-20 mt-2"
        style={{ marginLeft: "15px", marginRight: "15px" }}
      >
        <span className="color-red">
          {
            "All Offtake is represented and measured in kilowatts per hour (kWh). \
            The Offtake Balance and all Offtake purchases are offered and charged based on a 30 \
            day kWh consumption. The formula used to price Offtakes includes, but is not limited \
            to, the following: Offtake Purchase = Power Price x (kWh x 24H x 30D). For example: \
            Offtake Purchase = $ 0.05 x (339.81kWH x 24H x 30D) = $ 0.05 x 244,663 = $12,233. \
            The final Offtake Purchase price is then represented in Digital Energy Currency (DEC). \
            The DEC Offtake Balance is based on the market price of DEC at any given time."
          }
        </span>
      </div>
      <div>
        <br />
        <br />
      </div>
    </Modal>
  );
};

export default ShowPowerInfo;
