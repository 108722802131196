import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import moment from "moment";
import { toast } from "react-toastify";

import btcLogo from "../../assets/img/Orangebitcoin.png";
import dollarLogo from "../../assets/img/DollarBalanceImg.png";
import downSortLogo from "../../assets/img/down_sort_arrow.png";
import upSortLogo from "../../assets/img/up_sort_arrow.png";
import noSortLogo from "../../assets/img/default_sort.png";
import { CopyToClipboard } from "react-copy-to-clipboard";

import * as messageConstants from "../../utils/Messages";
import {
  pageProgress,
  niceNumberDecimalDisplay,
  getBtcUsd,
} from "../../utils/Util";
import Api from "../../services/api";
import TableHeaderTitle from "./TableHeaderTitle";

const defaultPagination = {
  totalSize: 0,
  page: 1,
  sizePerPage: 20,
  sortColumn: "status",
  sortDir: "desc",
};

const iconStyle = {
  marginTop: "4px",
  marginLeft: "6px",
  borderRadius: "40%",
  height: "16px",
  width: "16px",
};

const sortIconStyle = {
  marginTop: "0px",
  marginLeft: "2px",
  borderRadius: "20%",
  height: "15px",
  width: "15px",
};

const statusColors = {
  PENDING: "#5bc0de",
  PROCESSING: "#ffa500",
  APPROVED: "#24b314",
  REJECTED: "#c14242",
};

const commonColumnProps = {
  headerClasses: "custom-table-th",
  headerStyle: { verticalAlign: "top" },
};

function PowerSitesMarket(props) {
  const [tableData, setTableData] = useState([]);
  const [pagination, setPagination] = useState(defaultPagination);
  const [loading, setLoading] = useState(false);
  const [powerCostCurrency, setPowerCostCurrency] = useState("USD");
  const [btcUsd, setBtcUsd] = useState(1);
  const [decTokenPrice, setDecTokenPrice] = useState(0.05);

  if (
    props.location.state !== undefined &&
    props.location.state.returnState !== undefined
  ) {
    const returnState = props.location.state.returnState;
    setPagination({
      ...pagination,
      totalSize: returnState.totalSize,
      page: returnState.page,
      sizePerPage: returnState.sizePerPage,
      sortColumn: returnState.sortColumn,
      sortDir: returnState.sortDir,
    });
    // Make sure we do not continue to reset state...
    props.location.state.returnState = undefined;
  }

  useEffect(() => {
    document.title =
      "Offtake Market" +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
  }, []);

  useEffect(() => {
    fetchData();
  }, [pagination.page, pagination.sizePerPage]);

  const getPriceDEC = async () => {
    let decPrice = 0.05;
    const api = new Api();
    const priceResponse = await api.get("miner/StripePrice", {
      type: "DEC",
    });
    if (priceResponse.code === 200 && priceResponse.data) {
      if (priceResponse.data.stripePrice) {
        decPrice = Number(priceResponse.data.stripePrice.unitPrice);
      } else {
        decPrice = Number(priceResponse.data.unitPrice);
      }
    } else {
      toast.error("Failed to get current DEC price using default $0.05");
    }
    return decPrice;
  };

  const fetchData = async () => {
    const btcUsd = await getBtcUsd();
    setBtcUsd(btcUsd);
    const decPrice = await getPriceDEC();
    setDecTokenPrice(decPrice);
    // Here we only want to see campaigns that still have energy to offtake
    const hasAvailEnergy = true;
    const api = new Api();
    const { page, sizePerPage, sortColumn, sortDir } = pagination;
    setLoading(true);
    try {
      const { code, data, message } = await api.get("miner/allPowerSites", {
        page,
        sizePerPage,
        sortColumn,
        sortDir,
        hasAvailEnergy,
      });
      if (code === 200) {
        let { total, records } = data;
        records = records.map((row) => {
          const { dateOnline, dateOffline } = row;
          if (dateOnline && dateOffline) {
            row.stakingPeriod = moment(dateOffline).diff(
              moment(dateOnline),
              "days"
            );
            row.apr = Math.random() * 100;
          }
          return row;
        });
        setTableData(records);
        setPagination({
          ...pagination,
          totalSize: total,
        });
        setLoading(false);
        return pageProgress("remove");
      }
      throw new Error(message);
    } catch (err) {
      setLoading(false);
      pageProgress("force_remove");
      toast.error(err.message);
    }
  };

  const notImplemented = async () => {
    toast("NOT YET IMPLMENTED");
  };

  const onTableChange = (type, { page, sizePerPage }) => {
    if (type === "pagination" && !loading) {
      setPagination({
        ...pagination,
        page,
        sizePerPage,
      });
    }
  };

  const copySupplierId = (id) => {
    if (id === undefined) {
      toast.error("Missing supplier ID");
    } else {
      toast.success("Supplier ID successfully copied!");
    }
  };

  const changePowerCostCurrency = () => {
    const currency = powerCostCurrency === "USD" ? "BTC" : "USD";
    setPowerCostCurrency(currency);
  };

  const changeStatusSortDir = () => {
    if (pagination.sortColumn === "status") {
      pagination.sortDir = pagination.sortDir === "desc" ? "asc" : "desc";
    } else {
      pagination.sortDir = "desc";
      pagination.sortColumn = "status";
    }
    fetchData();
  };

  const changeExerciseSortDir = () => {
    if (pagination.sortColumn === "exerciseEnabled") {
      pagination.sortDir = pagination.sortDir === "desc" ? "asc" : "desc";
    } else {
      pagination.sortDir = "desc";
      pagination.sortColumn = "exerciseEnabled";
    }
    fetchData();
  };

  const changeLocationSortDir = () => {
    if (pagination.sortColumn === "location") {
      pagination.sortDir = pagination.sortDir === "desc" ? "asc" : "desc";
    } else {
      pagination.sortColumn = "location";
      pagination.sortDir = "desc";
    }
    fetchData();
  };

  /*
  const changeProdRateSortDir = () => {
    if (pagination.sortColumn === "dailyProductionMcf") {
      pagination.sortDir = pagination.sortDir === "desc" ? "asc" : "desc";
    } else {
      pagination.sortColumn = "dailyProductionMcf";
      pagination.sortDir = "desc";
    }
    fetchData();
  };
  */

  const changeEnergySourceSortDir = () => {
    if (pagination.sortColumn === "energySource") {
      pagination.sortDir = pagination.sortDir === "desc" ? "asc" : "desc";
    } else {
      pagination.sortColumn = "energySource";
      pagination.sortDir = "desc";
    }
    fetchData();
  };

  const changePowerCapSortDir = () => {
    if (pagination.sortColumn === "estimatedPoweCapacity") {
      pagination.sortDir = pagination.sortDir === "desc" ? "asc" : "desc";
    } else {
      pagination.sortColumn = "estimatedPoweCapacity";
      pagination.sortDir = "desc";
    }
    fetchData();
  };

  const changePowerCostSortDir = () => {
    if (pagination.sortColumn === "powerPrice") {
      pagination.sortDir = pagination.sortDir === "desc" ? "asc" : "desc";
    } else {
      pagination.sortColumn = "powerPrice";
      pagination.sortDir = "desc";
    }
    fetchData();
  };

  const findSortIcon = (col) => {
    if (col === pagination.sortColumn) {
      const isDescSort = pagination.sortDir === "desc";
      return isDescSort ? downSortLogo : upSortLogo;
    }
    return noSortLogo;
  };

  const isUsdCurrency = powerCostCurrency === "USD";

  const columns = [
    {
      ...commonColumnProps,
      text: "SUPPLIER ID",
      dataField: "supplierId",
      formatter: function (cell, row, rowIndex, formatExtraData) {
        return (
          <div>
            <span>{row.supplierId}</span>
            <CopyToClipboard
              text={row.supplierId}
              onCopy={() => copySupplierId(row.supplierId)}
            >
              <i
                className="fa fa-clipboard cursor-pointer ml-2 standardColorIcon"
                aria-hidden="true"
              ></i>
            </CopyToClipboard>
          </div>
        );
      },
    },
    {
      ...commonColumnProps,
      text: "LOCATION",
      dataField: "location",
      sort: true,
      headerFormatter() {
        const icon = findSortIcon("location");
        return (
          <div className="flex justify-content-center">
            <div className="text-center">
              <div>LOCATION</div>
            </div>
            <img
              src={icon}
              alt="-"
              style={sortIconStyle}
              onClick={changeLocationSortDir}
            />
          </div>
        );
      },
    },
    /*
    {
      ...commonColumnProps,
      text: "PRODUCTION RATE",
      align: "center",
      dataField: "dailyProductionMcf",
      headerFormatter() {
        const icon = findSortIcon("dailyProductionMcf");
        return (
          <div className="flex justify-content-center">
            <TableHeaderTitle title="PRODUCTION RATE" unit="Mcf/d" />
            <img
              src={icon}
              alt="-"
              style={sortIconStyle}
              onClick={changeProdRateSortDir}
            />
          </div>
        );
      },
    },
    */
    {
      ...commonColumnProps,
      text: "ENERGY SOURCE",
      align: "center",
      dataField: "energySource",
      headerFormatter() {
        const icon = findSortIcon("energySource");
        return (
          <div className="flex justify-content-center">
            <div className="text-center">
              <div>ENERGY SOURCE</div>
            </div>
            <img
              src={icon}
              alt="-"
              style={sortIconStyle}
              onClick={changeEnergySourceSortDir}
            />
          </div>
        );
      },
      formatter: (cell, row) => {
        const { dailyProductionMcf } = row;
        if (cell) {
          return <span>{cell}</span>;
        } else {
          if (dailyProductionMcf && dailyProductionMcf > 0.0) {
            return "Gas";
          }
          return "Unspecified";
        }
      },
    },
    {
      ...commonColumnProps,
      text: "POWER CAPACITY",
      align: "center",
      dataField: "estimatedPoweCapacity",
      headerFormatter() {
        const icon = findSortIcon("estimatedPoweCapacity");
        return (
          <div className="flex justify-content-center">
            <TableHeaderTitle title="POWER CAPACITY" unit="kWh" />
            <img
              src={icon}
              alt="-"
              style={sortIconStyle}
              onClick={changePowerCapSortDir}
            />
          </div>
        );
      },
      formatter(cell) {
        if (cell) {
          return niceNumberDecimalDisplay(cell, 2);
        }
        return "";
      },
    },
    {
      ...commonColumnProps,
      text: "DEC/kWh POWER COST",
      align: "center",
      dataField: "powerPrice",
      headerFormatter() {
        const icon = isUsdCurrency ? dollarLogo : btcLogo;
        const alt = isUsdCurrency ? "convert to BTC" : "convert to USD";
        const sortIcon = findSortIcon("powerPrice");
        return (
          <div className="flex justify-content-center">
            <TableHeaderTitle
              title="DEC/kWh POWER COST"
              unit={"Eq. " + powerCostCurrency + "/kWh"}
            />
            <img
              src={icon}
              alt={alt}
              style={iconStyle}
              onClick={changePowerCostCurrency}
            />
            <img
              src={sortIcon}
              alt="-"
              style={sortIconStyle}
              onClick={changePowerCostSortDir}
            />
          </div>
        );
      },
      formatter(cell, row) {
        if (cell) {
          let decPrice = 0.0;
          let usdPrice = 0.0;
          // Handle legacy case of USD price set and incomplete listing
          // Legacy is fixed at the start DEC price of 0.05 DEC/$
          if (row.powerPriceUnits && row.powerPriceUnits == "dec") {
            usdPrice = row.powerPrice * decTokenPrice;
            decPrice = row.powerPrice;
          } else {
            // Legacy, fixed DEC price
            usdPrice = row.powerPrice;
            decPrice = Math.ceil(row.powerPrice / 0.05);
          }
          const currencyText =
            " (" +
            (isUsdCurrency ? usdPrice + " USD" : usdPrice / btcUsd + " BTC") +
            ")";
          const costText = "" + decPrice + " " + currencyText;
          return costText;
        }
        return "";
      },
    },
    /*
    {
      ...commonColumnProps,
      text: "NOTIONAL VALUE",
      align: "center",
      dataField: "powerPrice",
      formatter(cell, row) {
        const { powerPrice, stakingPeriod } = row;
        if (powerPrice && stakingPeriod) {
          return 24 * stakingPeriod * powerPrice;
        }
        return "";
      },
    },
    {
      ...commonColumnProps,
      text: "STAKING PERIOD",
      dataField: "stakingPeriod",
      align: "center",
      formatter(cell) {
        if (cell) {
          const symbol = " " + (cell === 1 ? "day" : "days");
          return cell + symbol;
        }
        return "";
      },
    },
    {
      ...commonColumnProps,
      text: "STAKING APR",
      dataField: "apr",
      align: "center",
      headerFormatter() {
        return <TableHeaderTitle title="STAKING APR" unit="%" />;
      },
      formatter(cell) {
        if (cell) {
          return niceNumberDecimalDisplay(cell, 2);
        }
        return "";
      },
    },
    */
    {
      ...commonColumnProps,
      text: "STATUS",
      dataField: "status",
      sort: true,
      headerFormatter() {
        const icon = findSortIcon("status");
        return (
          <div className="flex justify-content-center">
            <div className="text-center">
              <div>STATUS</div>
            </div>
            <img
              src={icon}
              alt="-"
              style={sortIconStyle}
              onClick={changeStatusSortDir}
            />
          </div>
        );
      },
      formatter: (cell) => {
        if (cell) {
          const badgeColor = statusColors[cell.toUpperCase()];
          return (
            <span
              style={{
                borderRadius: 8,
                display: "inline-block",
                padding: "0.25em 0.4em",
                fontSize: "0.81em",
                fontWeight: 500,
                textAlign: "center",
                lineHeight: 1,
                color: "#fff",
                backgroundColor: badgeColor,
              }}
            >
              {cell.toLowerCase()}
            </span>
          );
        }
      },
    },
    {
      ...commonColumnProps,
      text: "Exercise",
      dataField: "exerciseEnabled",
      sort: true,
      headerFormatter() {
        const icon = findSortIcon("status");
        return (
          <div className="flex justify-content-center">
            <div className="text-center">
              <div>Exercise</div>
            </div>
            <img
              src={icon}
              alt="-"
              style={sortIconStyle}
              onClick={changeExerciseSortDir}
            />
          </div>
        );
      },
      formatter: (cell) => {
        const enabled = cell ? "enabled" : "disabled";
        const badgeColor =
          enabled === "enabled"
            ? statusColors["APPROVED"]
            : statusColors["REJECTED"];
        return (
          <span
            style={{
              borderRadius: 8,
              display: "inline-block",
              padding: "0.25em 0.4em",
              fontSize: "0.81em",
              fontWeight: 500,
              textAlign: "center",
              lineHeight: 1,
              color: "#fff",
              backgroundColor: badgeColor,
            }}
          >
            {enabled}
          </span>
        );
      },
    },
    {
      ...commonColumnProps,
      text: "ACTION",
      dataField: "action",
      formatter(cell, row) {
        return (
          <div className="text-left">
            <div className="d-inline-block">
              <div className="btn-group mr-1 mb-1">
                <button
                  aria-expanded="false"
                  aria-haspopup="true"
                  className="btn default-border-btn dropdown-toggle"
                  data-toggle="dropdown"
                  id="dropdownMenuButton6"
                  type="button"
                  disabled={
                    !row.powerPrice ||
                    !row.status ||
                    row.status.toLowerCase() !== "approved"
                  }
                >
                  Action
                </button>
                <div
                  aria-labelledby="dropdownMenuButton6"
                  className="dropdown-menu"
                >
                  {row.status &&
                    row.status.toLowerCase() === "approved" &&
                    (row.currentOfftake === undefined ||
                      row.estimatedPoweCapacity > 0) && (
                      <Link
                        to={{
                          pathname: "/buy_energy",
                          state: {
                            listingId: row._id,
                            supplierId: row.supplierId,
                            currentOfftake: row.currentOfftake,
                            currentDuration: row.offtakeDuration,
                            availEnergy: row.estimatedPoweCapacity,
                            energyCost: isUsdCurrency
                              ? row.powerPrice
                              : row.powerPrice / btcUsd,
                            returnState: pagination,
                          },
                        }}
                      >
                        <button
                          className="dropdown-item"
                          type="button"
                          disabled={loading}
                        >
                          Off-take
                        </button>
                      </Link>
                    )}
                  <button
                    className="dropdown-item"
                    type="button"
                    disabled={loading}
                    onClick={() => notImplemented()}
                  >
                    Terms & Conditions
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="adminDashboardContainer">
      <div className="content-i">
        <div className="content-box">
          <div>
            <div className="element-box">
              <h5 className="form-header flex justify-content-between">
                <span>Offtake Market</span>
                <span>
                  <Link to="/powersites">
                    <button type="button" className="mr-2 mb-2 btn btn-primary">
                      Go to My Offtakes
                    </button>
                  </Link>
                </span>
              </h5>
              <div>
                <div className="table-responsive">
                  <BootstrapTable
                    remote
                    bordered={false}
                    keyField="_id"
                    data={tableData}
                    columns={columns}
                    pagination={
                      pagination.totalSize > 0
                        ? paginationFactory(pagination)
                        : undefined
                    }
                    onTableChange={onTableChange}
                    noDataIndication="No Result"
                    loading={loading}
                    overlay={overlayFactory({
                      spinner: true,
                      styles: {
                        overlay: (base) => ({
                          ...base,
                          background: "rgba(192, 192, 192, 0.3)",
                        }),
                      },
                    })}
                    classes="table table-striped table-lightfont dataTable"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PowerSitesMarket;
