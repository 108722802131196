import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import decLogo from "../../assets/img/DecNewImg.png";
import dollarLogo from "../../assets/img/DollarBalanceImg.png";
import overlayFactory from "react-bootstrap-table2-overlay";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";
import { API_NETWORK, API_CONTRACTADDRESS } from "../../services/api-config";
import { toast } from "react-toastify";
import Api from "../../services/api";
import btcLogo from "../../assets/img/Orangebitcoin.png";
import BudgetModal from "../Layout/BudgetFoler/BudgetModal";
import {
  niceNumberDecimalDisplay,
  getBtcUsd,
  pageProgress,
} from "../../utils/Util";
import * as messageConstants from "../../utils/Messages";
import WithdrawModal from "./WithdrawModal";
import ConfirmationModal from "./ConfirmationModal";
import DepositModal from "./DepositModal";
import WalletTooltip from "./WalletTooltip";
import { connect } from "react-redux";
import { fetchUserDetails } from "../../store/actions/UserAction";
import { DEC_TO_USD } from "../../utils/constant";
import venlyLogo from "../../assets/img/VenlyImg.png";
import vbtc from "../../assets/img/vbtc.png";
let api;

class Wallet extends Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      earningsData: [],
      tableLoading: true,
      BudgetModalOpen: false,
      isHavingBudget: "",
      ethBalance: "",
      confirmationModalOpen: false,
      withdrawModalOpen: false,
      budgetBalance: "",
      depositModalOpen: false,
      paymentSource: "",
      earningsRenderFlag: false,
      decWalletAddress: "",
      decSecretKey: "",
      walletAddress: "",
      genrateAdddress: "",
      currency: "",
      btcBalance: "",
      decBalance: "",
      btcWalletAddress: "",
      btcUsdPrice: 0,
      decFetchErr: false,
      venlyBalance: 0,
      venlyBtcWalletAddress: "",
      venlyBtcWalletSecret: "",
    };
    pageProgress("force_remove");
    this.BudgetShowModal = this.BudgetShowModal.bind(this);
    this.onCloseModalBudget = this.onCloseModalBudget.bind(this);
    this.withdrawShowModal = this.withdrawShowModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.withdrawOnCloseModal = this.withdrawOnCloseModal.bind(this);
    this.generateWallet = this.generateWallet.bind(this);
    this.copyWhiteListAddress = this.copyWhiteListAddress.bind(this);
    this.confirmationOnCloseModal = this.confirmationOnCloseModal.bind(this);
    this.confirmationShowModal = this.confirmationShowModal.bind(this);
  }

  BudgetShowModal() {
    this.setState({
      BudgetModalOpen: true,
    });
  }

  onCloseModalBudget() {
    this.setState({ BudgetModalOpen: false });
  }

  copyWhiteListAddress() {
    let msg = "Address successfully copied!";
    toast.success(msg);
  }

  withdrawShowModal(asset, address) {
    this.setState({
      title: "",
      withdrawModalOpen: true,
      currency: asset,
      genrateAdddress: address,
    });
  }

  async confirmationShowModal() {
    const response = await api.create("user/wallet/import");
    if (response.code === 200) {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
    this.setState({
      title: "",
      confirmationModalOpen: true,
    });
  }
  ToastContent = ({ keystoreValue }) => (
    <div>
      <div style={{ marginBottom: "10px" }}>{keystoreValue}</div>
      <button
        onClick={() => {
          navigator.clipboard
            .writeText(keystoreValue)
            .then(() => {
              toast.success("Secret copied to clipboard!", {
                autoClose: 2000,
                position: "bottom-right",
              });
            })
            .catch((err) => {
              toast.error("Failed to copy to clipboard");
            });
        }}
        style={{
          padding: "8px 16px",
          backgroundColor: "#4CAF50",
          color: "white",
          border: "none",
          borderRadius: "4px",
          cursor: "pointer",
          marginTop: "8px",
        }}
      >
        Copy Secret
      </button>
    </div>
  );
  async generateVenlyWallet() {
    try {
      toast.warn("please wait");
      const response = await api.create("miner/getvenlyBtcAddresses");
      let btcAddress;
      if (response.code === 200 && response.data) {
        btcAddress = response.data?.btcAddress
          ? response.data?.btcAddress
          : response.data?.wallet?.address;
        this.setState({
          venlyBalance: response.data?.balance?.wallet?.balance ?? 0,
          venlyBtcWalletAddress: btcAddress,
          venlyBtcWalletSecret: response.data?.wallet?.exportedData?.result
            ?.keystore
            ? JSON.parse(response.data?.wallet?.exportedData?.result?.keystore)
                ?.value
            : "",
        });
        toast.info(
          this.ToastContent({
            keystoreValue: response.data?.wallet?.exportedData?.result?.keystore
              ? JSON.parse(
                  response.data?.wallet?.exportedData?.result?.keystore
                )?.value
              : "Error",
          }),
          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
            closeButton: false,
          }
        );
      }

      const newData = this.state.earningsData.map((d) => {
        if (d.asset === "VENLY") {
          return {
            asset: "VENLY",
            operations: false,
            showWalletAddress: true,
            showGenerateWalletAddress: false,
            balance: niceNumberDecimalDisplay(this.state.venlyBalance, 8),
            walletAddress: btcAddress,
          };
        }
        return d;
      });
      this.setState({
        earningsData: newData,
      });
    } catch (error) {
      toast.error("Failed to generate Venly wallet. Please try again.");
    }
  }
  async generateWallet() {
    const response = await api.create("miner/getEthAddresses");

    if (response.code === 200 && response.data) {
      this.setState({
        decWalletAddress: response.data.EthAddress,
        decSecretKey: response.data.secretKey,
      });
    }
    const newData = this.state.earningsData.map((d) => {
      if (d.asset === "DEC") {
        return {
          asset: "DEC",
          balance: niceNumberDecimalDisplay(this.state.ethBalance, 2),
          operations: false,
          walletAddress: this.state.decWalletAddress,
          showWalletAddress: true,
          showGenerateWalletAddress: false,
        };
      }
      return d;
    });
    this.setState({
      earningsData: newData,
    });
  }

  onCloseModal() {
    this.setState({ depositModalOpen: false });
  }

  confirmationOnCloseModal() {
    this.setState({ confirmationModalOpen: false });
  }

  withdrawOnCloseModal() {
    this.setState({ withdrawModalOpen: false });
  }

  async componentDidMount() {
    document.title =
      "Wallets" +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    this.props.fetchUserDetails(async (userDetails) => {
      await this.getRecords(userDetails);
    });
  }

  async getRecords(authUserInfo) {
    try {
      const priceUsd = await getBtcUsd();
      this.setState({ btcUsdPrice: priceUsd });
      if (!(authUserInfo && authUserInfo.btcWalletAddress)) {
        toast.warn(
          "BTC Wallet will be generated once you have at least one worker"
        );
      }

      if (authUserInfo && authUserInfo.receivingEthAddress) {
        try {
          const userEthBalance = await axios.get(
            `${API_NETWORK}?module=account&action=tokenbalance&contractaddress=${API_CONTRACTADDRESS}&address=${authUserInfo.receivingEthAddress}&tag=latest&apikey=APJX4Q44UGSK2EUNQYUY31YSW8UGPY2D6V`,
            {
              headers: "",
              timeout: 5000,
            }
          );

          if (userEthBalance.status === 200) {
            // TODO: logic here needs commenting!
            let finalTokenValue = Math.ceil(Number(userEthBalance.data.result));
            finalTokenValue = finalTokenValue / Math.pow(10, 18);
            this.setState({
              ethBalance: finalTokenValue,
              decWalletAddress: authUserInfo.receivingEthAddress,
              decFetchErr: false,
            });
          }
        } catch (err) {
          this.state.decFetchErr = true;
          console.log(err.message);
          toast(err.message + ": Getting DEC Balance");
        }
      }

      if (authUserInfo && authUserInfo.btcWalletAddress) {
        const response = await api.get("miner/checkBalanceBtc");

        if (response.code === 200 && response.data && response.data.Balance) {
          this.setState({
            btcBalance: response.data.Balance.available,
            btcWalletAddress: authUserInfo.btcWalletAddress,
          });
        }
      }

      if (authUserInfo && authUserInfo.isHavingBudget) {
        const budgetResponse = await api.get("miner/viewBudget", {
          type: "budget",
        });

        if (
          budgetResponse.code === 200 &&
          budgetResponse.data &&
          budgetResponse.data.budgetedMiner
        ) {
          this.setState({
            budgetBalance: budgetResponse.data.budgetedMiner.amountLeft,
            budgetBtcBalance: budgetResponse.data.budgetedMiner.amountLeftinBTC,
          });
        }
      }

      let earningsData = [
        {
          asset: "ASIC BUDGET",
          balance: niceNumberDecimalDisplay(this.state.budgetBalance, 2),
        },
        {
          asset: "BTC",
          balance: niceNumberDecimalDisplay(this.state.btcBalance, 8),
          walletAddress: authUserInfo.btcWalletAddress,
        },
        {
          asset: "DEC",
          balance: niceNumberDecimalDisplay(this.state.ethBalance, 2),
          walletAddress: authUserInfo.receivingEthAddress,
          operations: authUserInfo.receivingEthAddress ? false : true,
        },
        {
          asset: "VENLY",
          balance: niceNumberDecimalDisplay(this.state.venlyBalance, 8),
          walletAddress: authUserInfo.venlyBtcWalletAddress,
          operations: !!authUserInfo.venlyBtcWalletAddress ? false : true,
        },
      ];
      this.setState({
        earningsData,
        tableLoading: false,
        earningsRenderFlag: true,
      });
    } catch (e) {
      console.log(e);
    }
  }

  renderTotal() {
    const { budgetBalance, btcBalance, ethBalance } = this.state;
    const total =
      (budgetBalance || 0) +
      (this.btcToUsd(btcBalance) || 0) +
      (this.decToUsd(ethBalance) || 0);
    return (
      <h4 className="card-title-font form-table-header">
        <span className="walletFont">
          {" "}
          <span className="text-bold">Total Balance: </span>
          {niceNumberDecimalDisplay(total, 2) || "0.00"} USD
        </span>
      </h4>
    );
  }

  btcToUsd(balance) {
    const { btcUsdPrice } = this.state;
    return balance * btcUsdPrice;
  }

  decToUsd(balance) {
    return balance * DEC_TO_USD;
  }

  convertBTCToUSD(balance) {
    const balanceInUSD = this.btcToUsd(balance);
    return niceNumberDecimalDisplay(balanceInUSD, 2) || "0.00";
  }

  convertDECToUSD(balance) {
    const balanceInUSD = this.decToUsd(balance);
    return niceNumberDecimalDisplay(balanceInUSD, 2) || "0.00";
  }

  renderBalance(cell, asset) {
    const { btcBalance, ethBalance, budgetBtcBalance } = this.state;

    let balance1 = cell;
    let balance2 = niceNumberDecimalDisplay(budgetBtcBalance, 8) || "0.00";
    let currency1 = "USD";
    let currency2 = "DEC";
    if (asset === "BTC") {
      balance1 = niceNumberDecimalDisplay(btcBalance, 8) || "0.00";
      balance2 = this.convertBTCToUSD(btcBalance);
      currency1 = "BTC";
      currency2 = "USD";
    } else if (asset === "DEC") {
      balance1 = niceNumberDecimalDisplay(ethBalance, 2) || "0.00";
      balance2 = this.convertDECToUSD(ethBalance);
      currency1 = "DEC";
      currency2 = "USD";
    } else if (asset === "VENLY") {
      balance1 = niceNumberDecimalDisplay(btcBalance, 8) || "0.00";
      balance2 = this.convertBTCToUSD(btcBalance);
      currency1 = "BTC";
      currency2 = "USD";
    }
    return (
      <>
        <div className="mr-2 font-size-16">
          {balance1} {currency1}
        </div>
        <div className="mr-2 font-size-16">
          {balance2} {currency2}
        </div>
      </>
    );
  }

  render() {
    const {
      earningsData,
      earningsRenderFlag,
      tableLoading,
      workerLoading,
      BudgetModalOpen,
      btcBalance,
      decBalance,
    } = this.state;

    const _this = this;
    const workerColumns = [
      {
        headerClasses: "custom-table-th",
        style: { whiteSpace: "nowrap" },
        dataField: "asset",
        text: "ASSET",
        formatter: function (cell) {
          let logoSrc = btcLogo;
          let style = {
            marginRight: "5px",
            marginLeft: "5px",
            height: "70px",
            width: "70px",
          };
          let title = "Bitcoin";
          let currency = "BTC";
          if (cell === "DEC") {
            logoSrc = decLogo;
            style = {
              marginRight: "5px",
              marginLeft: "6px",
              borderRadius: "50%",
              height: "65px",
              width: "65px",
            };
            title = "Digital Energy Currency";
            currency = "DEC";
          } else if (cell === "ASIC BUDGET") {
            logoSrc = dollarLogo;
            style = {
              marginRight: "7px",
              marginLeft: "11px",
              borderRadius: "50%",
              height: "60px",
              width: "60px",
            };
            title = "ASIC BUDGET";
            currency = "USD";
          } else if (cell === "VENLY") {
            logoSrc = venlyLogo;
            // vbtc logo
            style = {
              marginRight: "7px",
              marginLeft: "11px",
              borderRadius: "50%",
              height: "60px",
              width: "60px",
            };
            title = "New BTC WALLET";
            // currency = "USD";
          }

          return (
            <div className="logowithText">
              <span className="p-inherit">
                <div style={{ backgroundColor: "" }}>
                  <div>
                    <img
                      src={logoSrc}
                      className="setDeclogo"
                      alt="DEC Logo"
                      height="65px"
                      width="65px"
                      style={style}
                    />
                  </div>
                </div>
              </span>

              <div className="decText ml-1">
                <span className="text-bold">{title}</span>
                {cell === "BTC" && (
                  <span className="custom-tooltip-coin1  justify-content-center ml-1 mr-1">
                    <i
                      className=" icon-info ml-md-1 mr-1 ml-sm-1 ml-xs-1 "
                      style={{
                        color: "#999999",
                        cursor: "pointer",
                      }}
                    ></i>
                    <WalletTooltip />
                  </span>
                )}
                <br />
                <span>({currency})</span>
              </div>
            </div>
          );
        },
      },
      {
        headerClasses: "custom-table-th",
        dataField: "balance",
        text: "BALANCE",
        headerFormatter: function () {
          return (
            <span className="vertical-center justify-content-start">
              <span className="mr-2">BALANCE</span>
            </span>
          );
        },
        formatter: function (cell, row) {
          return (
            <div className="balanceInfo" style={{ whiteSpace: "nowrap" }}>
              {_this.renderBalance(cell, row.asset)}
            </div>
          );
        },
      },
      {
        headerClasses: "custom-table-th",
        dataField: "walletAddress",
        text: "WALLET ADDRESS",
        isDummyField: true,
        formatter: function (cell, row) {
          const smallAddress = row.walletAddress
            ? `${row.walletAddress.slice(0, 3)}...${row.walletAddress.slice(
                -3
              )}`
            : "";
          if (row.walletAddress) {
            return (
              <div>
                <span style={{ color: "gray" }}>{smallAddress}</span>
                <CopyToClipboard
                  text={row.walletAddress}
                  onCopy={() => _this.copyWhiteListAddress()}
                >
                  <i
                    className="fa fa-clipboard cursor-pointer ml-2 standardColorIcon"
                    aria-hidden="true"
                  />
                </CopyToClipboard>
              </div>
            );
          } else if (row.walletAddress === "" && row.asset === "DEC") {
            return (
              <button
                className="btn btn-primary"
                onClick={() => _this.generateWallet()} // Removed _this
              >
                Generate Wallet
              </button>
            );
          } else if (!row.venlyBtcWalletAddress && row.asset === "VENLY") {
            return (
              <button
                className="btn btn-primary"
                onClick={() => _this.generateVenlyWallet()}
              >
                Generate Wallet
              </button>
            );
          }

          return null; // Default return if no conditions match
        },
      },
      {
        headerClasses: "custom-table-th",
        dataField: "operations",
        text: "OPERATIONS",
        formatter: function (cell, row) {
          return (
            <div className="btn-group mr-1 mb-1">
              <div>
                {row.asset === "BTC" ? (
                  <Link to={`wallets/view`}>
                    <button
                      className="btn btn-primary mr-2"
                      type="button"
                      disabled={!row.walletAddress}
                    >
                      View
                    </button>
                  </Link>
                ) : row.asset === "DEC" ? (
                  <Link
                    to={{
                      pathname: "wallets/viewEth",
                      state: { decAddress: row.walletAddress },
                    }}
                  >
                    <button
                      className="btn btn-primary mr-2"
                      type="button"
                      disabled={!row.walletAddress || _this.state.decFetchErr}
                    >
                      View
                    </button>
                  </Link>
                ) : row.asset === "ASIC BUDGET" ? (
                  <Link
                    to={{
                      pathname: "/wallets/viewBudget",
                    }}
                  >
                    <button className="btn btn-primary mr-2" type="button">
                      View
                    </button>
                  </Link>
                ) : (
                  ""
                )}
                {row.asset === "BTC" ? (
                  <button
                    className="btn btn-primary mr-2"
                    type="button"
                    disabled={!row.walletAddress}
                    onClick={() => _this.withdrawShowModal(row.asset)}
                  >
                    Withdraw
                  </button>
                ) : row.asset === "DEC" ? (
                  ""
                ) : row.asset === "ASIC BUDGET" ? (
                  <button
                    className="btn btn-primary mr-2"
                    type="button"
                    onClick={() => {
                      _this.setState({ paymentSource: "budget" }, () => {
                        _this.BudgetShowModal();
                      });
                    }}
                  >
                    Deposit
                  </button>
                ) : (
                  ""
                )}
                {row.asset === "DEC" ? (
                  <button
                    className="btn btn-primary mr-2"
                    type="button"
                    disabled={!row.walletAddress || _this.state.decFetchErr}
                    onClick={() => {
                      _this.setState({ paymentSource: "DEC" }, () => {
                        _this.BudgetShowModal();
                      });
                    }}
                  >
                    Buy
                  </button>
                ) : (
                  ""
                )}
              </div>
              {row.walletAddress && row.asset === "DEC" ? (
                <div id="keyLeft">
                  <span
                    className=" custom-tooltip row justify-content-center ml-2 mr-3"
                    tooltip-title={`import Wallet`}
                  >
                    <i
                      className=" fa fa-key privateKey fa-lg mt-2 mr-3 standardColorIcon"
                      aria-hidden="true"
                      onClick={() => {
                        _this.confirmationShowModal();
                      }}
                    ></i>
                  </span>
                  <CopyToClipboard
                    text={API_CONTRACTADDRESS}
                    onCopy={() => _this.copyWhiteListAddress()}
                  >
                    <span
                      className="custom-tooltip row justify-content-center"
                      tooltip-title={`Copy Smart Contract Address`}
                    >
                      <i className=" fa fa-id-badge fa-lg standardColorIcon"></i>
                    </span>
                  </CopyToClipboard>
                </div>
              ) : (
                ""
              )}
            </div>
          );
        },
      },
    ];

    const WorkerRemoteAll = ({ data, page, sizePerPage, totalSize }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          keyField="_id"
          bordered={false}
          loading={workerLoading}
          data={data}
          columns={workerColumns}
          noDataIndication="No results!"
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable"
        />
      </div>
    );

    WorkerRemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
    };

    return (
      <div className="buy-xpr-container">
        <div className="content-i">
          <div className="content-box">
            <div className="element-box card-inner-padding mgtp-xl-4 mgtp-lg-4 mgtp-xs-4 mgtp-md-4 mgtp-sm-4 ">
              <div className="earnings-table table-responsive">
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "5px",
                      alignItems: "center !important",
                      paddingTop: "5px",
                    }}
                  >
                    {this.renderTotal()}
                  </div>
                </div>
                <div>
                  {tableLoading && (
                    <div className="text-center mb-2 mt-2">
                      <span id="loading" color="#047bf8" />
                    </div>
                  )}
                </div>
                {earningsRenderFlag === true && (
                  <WorkerRemoteAll data={earningsData} />
                )}
              </div>
              <WithdrawModal
                {...this.props}
                {...this.state}
                withdrawOnCloseModal={this.withdrawOnCloseModal}
                btcBalance={btcBalance}
                decBalance={decBalance}
                onInputValueChange={this.onchange}
              />
              <DepositModal
                {...this.props}
                {...this.state}
                depositOnCloseModal={this.onCloseModal}
                onInputValueChange={this.onchange}
              />
              <ConfirmationModal
                {...this.props}
                {...this.state}
                confirmationOnCloseModal={this.confirmationOnCloseModal}
                onInputValueChange={this.onchange}
              />
            </div>
          </div>
          <BudgetModal
            authToken={this.props.authToken}
            onCloseModalBudget={this.onCloseModalBudget}
            BudgetModalOpen={BudgetModalOpen}
            paymentSource={this.state.paymentSource}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userDetails: state.user.userDetails,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserDetails: (success) => dispatch(fetchUserDetails(success)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Wallet);
