import React, { Component } from "react";
import * as messageConstants from "../../utils/Messages";
import { pageProgress } from "../../utils/Util";

import { withStyles } from "@material-ui/core/styles";
import {
  Stepper,
  Step,
  StepContent,
  Button,
  Paper,
  Typography,
} from "@material-ui/core";

import Product from "./Product";
import PaymentMethod from "./PaymentMethod";
import Calculator from "../MiningCalculator/MiningCalculator";
import Checkout from "./Checkout";
import { toast } from "react-toastify";
import {
  hashrateConverter,
  powerConsumptionConverter,
} from "../MiningCalculator/helper";

import Api from "../../services/api";

const useStyles = (theme) => ({
  root: {
    width: "100%",
    backgroundColor: "red",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  label: {
    fontSize: "20px",
    fontWeight: 600,
  },
});

let api;

// XXX
class UserJourney extends Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      activeStep: 0,
      products: [],
      selectedProduct: null,
      quantity: 1,
      monthlyElectricityCost: 0,
    };
    pageProgress("force_remove");
    document.title =
      messageConstants.USER_JOURNEY_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;

    this.getSteps = this.getSteps.bind(this);
    this.getStepContent = this.getStepContent.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.getAllProducts = this.getAllProducts.bind(this);
    this.onSelected = this.onSelected.bind(this);
    this.onQuantity = this.onQuantity.bind(this);

    if (
      props.location.state !== undefined &&
      props.location.state.listingId !== undefined
    ) {
      // If these fields are set miner want to buy HW to consume
      // their purchased kWh of energy. The kWh they have available
      // are passed in. If a mine has say 300 kWh available in offtake
      // and they want to buy machines with a power consumption of
      // 3010 W  = 3.01 kWh they would need 99 machines. These machines
      // consume 297.99 kWh of power leaving 2.01 kWh unused. Over a
      // 12 month duration this is 17607.6 kW, adding ~59 hours to our
      // duration. This seems reasonable and selecting say 100 machines,
      // while it would come closer to the duration, would result in
      // a slightly shorter duration which may be undesirable.

      // This code, when these value are set, should compute the number
      // of machines needed of the selected type and may optionally
      // report the slight excess in duration to be expected. Once
      // the user has successfully exercised the code then needs to
      // update the user offtake entry (by ID) to set currentOfftake
      // to 0 and increment exercisedOfftake by the exercised amount.
      this.exercisedKWH = props.location.state.currentOfftake;
      this.offtakeDuration = props.location.state.offtakeDuration;
      this.userEntryId = props.location.state.userEntryId;
      this.supplierId = props.location.state.supplierId;
    }
  }

  getSteps() {
    return [
      "Step 1: Select Your ASIC Miner",
      "Step 2: Calculate Profitability Expectations",
      "Step 3: Confirm Your Order",
      "Step 4: Select Your Payment Method",
    ];
  }

  onSelected = (selectedProduct) => {
    this.setState({ selectedProduct });
  };

  onQuantity = (quantity) => {
    this.setState({ quantity });
  };

  getStepContent(step) {
    const {
      products,
      selectedProduct,
      quantity,
      monthlyElectricityCost,
      orderId,
    } = this.state;

    const totalAmount =
      monthlyElectricityCost * 2 +
      quantity *
        (selectedProduct
          ? Number(
              products.filter((p) => p._id === selectedProduct)[0].priceInUsd
            )
          : 0);
    switch (step) {
      case 0:
        return (
          <Product
            products={products}
            selectedProduct={selectedProduct}
            quantity={quantity}
            onSelected={this.onSelected}
            onQuantity={this.onQuantity}
          />
        );
      case 1:
        return (
          <Calculator
            selectedProduct={
              products.filter((p) => p._id === selectedProduct)[0]
            }
            quantity={quantity}
            onCalculate={(data) => {
              this.setState({ ...data });
            }}
            readOnly={true}
          />
        );
      case 2:
        return (
          <Checkout
            selectedProduct={
              products.filter((p) => p._id === selectedProduct)[0]
            }
            totalAmount={totalAmount}
            quantity={quantity}
            monthlyElectricityCost={monthlyElectricityCost}
            onCheckOutQuantity={async (quantity) => {
              this.setState({ quantity }, () => {
                this.calculate();
              });
            }}
          />
        );
      case 3:
        return (
          <PaymentMethod
            transactionAmount={totalAmount}
            selectedProduct={selectedProduct}
            quantity={quantity}
            monthlyElectricityCost={monthlyElectricityCost}
            orderId={orderId}
            onUpload={() => {
              this.setState({ updateCheckout: true });
            }}
          />
        );
      default:
        return "Unknown step";
    }
  }

  handleNext = () => {
    this.setState({ activeStep: this.state.activeStep + 1 }, () => {
      if (this.state.activeStep === 4) {
        this.props.history.push("/subscriptions");
      }
    });
  };

  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };

  handleReset = () => {
    this.setState({ activeStep: 0 });
  };

  async getAllProducts() {
    const response = await api.get("eshop/machines");

    if (response.code === 200) {
      this.setState({
        products: response.data.machines,
      });
    }
  }

  calculate = async () => {
    const {
      hashRateType,
      hashRate,
      powerConsumptionType,
      powerConsumption,
      powerCost,
      blockReward,
      difficultyRate,
      exchangeRate,
      poolFees,
      costPerTeraHash,
      quantity,
      selectedProduct,
      products,
    } = this.state;

    const product = products.filter((p) => p._id === selectedProduct)[0];
    const budget = quantity * Number(product.priceInUsd);

    if (
      budget &&
      hashRate &&
      powerConsumption &&
      powerCost &&
      poolFees &&
      exchangeRate &&
      costPerTeraHash
    ) {
      const calculatedHashRate = hashrateConverter(
        hashRateType,
        "",
        hashRate,
        "server"
      );
      const calculatePowerConsumption = powerConsumptionConverter(
        powerConsumptionType,
        "",
        powerConsumption,
        "server"
      );
      const response = await api.create("user/mining/calculate", {
        hashRate: calculatedHashRate,
        powerConsumption: calculatePowerConsumption,
        powerCost,
        blockReward,
        difficultyRate,
        exchangeRate,
        poolFees,
        budget,
        costPerTeraHash,
      });
      if (response.code === 200) {
        this.setState({
          monthlyElectricityCost: response.data[1]?.electricityCostUsd,
        });
      }
    } else {
      let errorMsg = "Please enter valid details for required fields.";
      if (!budget) {
        errorMsg = "Please enter valid budget.";
      } else if (!costPerTeraHash) {
        errorMsg = "Please enter valid cost per terahash.";
      } else if (!hashRate) {
        errorMsg = "Please enter valid hash rate.";
      } else if (!powerConsumption) {
        errorMsg = "Please enter valid power consumption.";
      } else if (!powerCost) {
        errorMsg = "Please enter valid power cost.";
      } else if (!poolFees) {
        errorMsg = "Please enter valid pool fees.";
      } else if (!blockReward) {
        errorMsg = "Please enter valid block reward.";
      } else if (!exchangeRate) {
        errorMsg = "Please enter valid exchange rate.";
      }
      toast.error(errorMsg);
    }
  };

  async componentDidMount() {
    await this.getAllProducts();
    const query = new URLSearchParams(this.props.history.location.search);
    const selectedProduct = query.get("selectedProduct");
    const quantity = query.get("quantity");
    const monthlyElectricityCost = query.get("monthlyElectricityCost");
    const orderId = query.get("orderId");
    const updateCheckout = query.get("updateCheckout");

    if (
      !!selectedProduct &&
      !!quantity & !!monthlyElectricityCost & !!orderId
    ) {
      const shouldUpdtaeCheckout = Number(updateCheckout) === 1;
      this.setState({
        selectedProduct,
        quantity,
        monthlyElectricityCost,
        activeStep: shouldUpdtaeCheckout ? 3 : 1,
        orderId,
      });

      if (shouldUpdtaeCheckout) {
        const res = await api.create("eshop/updateCheckout", {
          orderId,
        });

        if (res.code === 200) {
          this.setState({ updateCheckout: true });
        } else {
          toast.error(res.message);
        }
      }
      window.history.replaceState(null, "", window.location.pathname);
    } else {
      this.setState({
        activeStep: 0,
      });
    }
  }

  render() {
    const steps = this.getSteps();
    const {
      activeStep,
      selectedProduct,
      quantity,
      monthlyElectricityCost,
      updateCheckout,
    } = this.state;
    const { classes } = this.props;

    return (
      <div className="buy-xpr-container">
        <div className="content-i">
          <div className="content-box">
            <div className={classes.root}>
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => {
                  const component = this.getStepContent(index);
                  return (
                    <Step key={label}>
                      <Typography className={classes.label}>{label}</Typography>
                      <StepContent>
                        <>{component}</>
                        <div className={classes.actionsContainer}>
                          <div>
                            <Button
                              disabled={activeStep === 0}
                              onClick={this.handleBack}
                              className={classes.button}
                            >
                              Back
                            </Button>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={this.handleNext}
                              className={classes.button}
                              disabled={
                                (activeStep === 0 &&
                                  (!selectedProduct || Number(quantity) < 1)) ||
                                (activeStep === 1 &&
                                  monthlyElectricityCost === 0) ||
                                (activeStep === 3 && !updateCheckout)
                              }
                            >
                              {activeStep === steps.length - 1
                                ? "Go To Subscription"
                                : activeStep === 2
                                ? "Proceed to checkout"
                                : "Next"}
                            </Button>
                          </div>
                        </div>
                      </StepContent>
                    </Step>
                  );
                })}
              </Stepper>
              {activeStep === steps.length && (
                <Paper square elevation={0} className={classes.resetContainer}>
                  <Typography>
                    All steps completed - you&apos;re finished
                  </Typography>
                  <Button onClick={this.handleReset} className={classes.button}>
                    Reset
                  </Button>
                </Paper>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(useStyles)(UserJourney);
