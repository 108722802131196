import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";

import decLogo from "../../assets/img/DecNewImg.png";
import axios from "axios";
import { API_NETWORK, API_CONTRACTADDRESS } from "../../services/api-config";
import { fetchUserDetails } from "../../store/actions/UserAction";
import { useDispatch } from "react-redux";

import * as messageConstants from "../../utils/Messages";
import { niceNumberDecimalDisplay, pageProgress } from "../../utils/Util";
import Api from "../../services/api";

const defaultListingData = {
  listingId: "",
  supplierId: "",
  estimatedPoweCapacity: 0, // kWh
  powerPrice: 0, // DEC per kWh
  powerPriceUSD: 0, // USD per kWh
  offtakePercentage: 25,
  offtakePercentageLabel: "25%",
  offtakeDuration: 365 * 24,
  offtakeDurationLabel: "12 Months",
  currentOfftake: 0.0,
  newOfftake: 0.0,
  powerCost: 0.0, // in DEC
  powerCostUSD: 0.0, // in USD
  decPricePerToken: 0.05,
  ethBalance: 0.0,
  decWalletAddress: "",
};

const offtakePercentageList = {
  choices: [
    //{ value: 5, label: "5%" },
    //{ value: 10, label: "10%" },
    //{ value: 15, label: "15%" },
    //{ value: 20, label: "20%" },
    { value: 25, label: "25%" },
    //{ value: 30, label: "30%" },
    //{ value: 35, label: "35%" },
    //{ value: 40, label: "40%" },
    //{ value: 45, label: "45%" },
    { value: 50, label: "50%" },
    //{ value: 55, label: "55%" },
    //{ value: 60, label: "60%" },
    //{ value: 65, label: "65%" },
    //{ value: 70, label: "70%" },
    //{ value: 75, label: "75%" },
    //{ value: 80, label: "80%" },
    //{ value: 85, label: "85%" },
    //{ value: 90, label: "90%" },
    //{ value: 95, label: "95%" },
    { value: 100, label: "100%" },
  ],
};

// 12 months, 18 months, and 24 months
// price is per kWh - hours = days * 24
const offtakeDurationList = {
  choices: [
    { value: 365 * 24, label: "365 days (12 months)" },
    { value: 545 * 24, label: "545 days (~18 months)" },
    { value: 730 * 24, label: "730 days (24 months)" },
  ],
};

function MinerBuyEnergy(props) {
  const dispatch = useDispatch();
  const [listingData, setListingData] = useState(defaultListingData);

  if (props === undefined) {
    toast("Props is undef");
  }
  if (props.location.state === undefined) {
    toast("No state!");
  }

  if (
    props.location.state !== undefined &&
    props.location.state.paymentReturnState !== undefined
  ) {
    // Return from payment pop-up, reset state
    listingData.listingId = props.location.state.paymentReturnState.listingId;
    listingData.supplierId = props.location.state.paymentReturnState.supplierId;
    listingData.currentOfftake =
      props.location.state.paymentReturnState.currentOfftake;
    listingData.offtakeDuration =
      props.location.state.paymentReturnState.currentDuration;
    listingData.returnState =
      props.location.state.paymentReturnState.returnState;
  } else {
    listingData.listingId = props.location.state.listingId;
    listingData.supplierId = props.location.state.supplierId;
    listingData.currentOfftake = props.location.state.currentOfftake;
    listingData.offtakeDuration = props.location.state.currentDuration;
    listingData.returnState = props.location.state.returnState;
  }

  if (
    listingData.offtakeDuration === undefined ||
    listingData.offtakeDuration < 365 * 24 ||
    listingData.currentOfftake == 0.0
  ) {
    listingData.offtakeDuration = 365 * 24;
    listingData.offtakeDurationLabel = "365 days (12 months)";
  } else {
    listingData.fixedDuration = true; // we do not allow mixed durations
    if (listingData.offtakeDuration == 365 * 24) {
      listingData.offtakeDurationLabel = "365 days (12 months)";
    } else if (listingData.offtakeDuration == 545 * 24) {
      listingData.offtakeDurationLabel = "545 days (~18 months)";
    } else if (listingData.offtakeDuration == 730 * 24) {
      listingData.offtakeDurationLabel = "730 days (24 months)";
    } else {
      listingData.offtakeDurationLabel = "?";
    }
  }

  if (listingData.currentOfftake === undefined || !listingData.currentOfftake) {
    listingData.currentOfftake = 0.0;
  }
  //console.log(listingData.currentOfftake);

  useEffect(() => {
    document.title =
      "Buy Energy" +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    dispatch(fetchUserDetails(setUserDetails, setUserDetailsError));
  }, []);

  const setUserDetails = async (data) => {
    listingData.userDetails = data;
    await getDECBalance();
    fetchData();
  };

  const setUserDetailsError = async (err) => {
    toast.error("Unable to fetch user details");
    console.log(err);
  };

  const fetchData = async () => {
    if (!listingData.listingId) {
      toast.error("No listing ID");
    }
    const listingId = listingData.listingId;
    await getPriceDEC();
    const api = new Api();
    try {
      const { code, data, message } = await api.get("miner/fetchListingById", {
        listingId,
      });
      if (code === 200) {
        let { total, listing } = data;
        if (listing && total === 1 && listing.powerPrice) {
          const estimatedPoweCapacity = listing.estimatedPoweCapacity;
          let decPrice = 0.0;
          let usdPrice = 0.0;
          // Handle legacy case of USD price set and incomplete listing
          // Legacy is fixed at the start DEC price of 0.05 DEC/$
          if (listing.powerPriceUnits && listing.powerPriceUnits == "dec") {
            usdPrice = listing.powerPrice * listingData.decPricePerToken;
            decPrice = listing.powerPrice;
          } else {
            // Legacy, fixed DEC price
            usdPrice = listing.powerPrice;
            decPrice = Math.ceil(listing.powerPrice / 0.05);
          }
          const powerPrice = decPrice;
          const powerPriceUSD = usdPrice;
          const newOfftake =
            (listingData.offtakePercentage / 100.0) * estimatedPoweCapacity;
          const powerCost = Math.ceil(
            newOfftake * listingData.offtakeDuration * powerPrice
          ); // in DEC
          const powerCostUSD = powerCost * listingData.decPricePerToken; // in USD

          setListingData({
            ...listingData,
            listingId,
            estimatedPoweCapacity,
            newOfftake,
            powerPrice, // DEC per kWh
            powerPriceUSD, // USD per kWh
            powerCost, // total DEC cost
            powerCostUSD,
          }); // total USD at current $/DEC rate
          return pageProgress("remove");
        } else {
          toast.error("No listing or power price found for supplier");
          return pageProgress("remove");
        }
      }
      throw new Error(message);
    } catch (err) {
      console.log(err.message);
      toast.error(err.message);
    }
  };

  // TODO: Move to utils?
  const getDECBalance = async () => {
    if (
      listingData.userDetails &&
      listingData.userDetails.receivingEthAddress
    ) {
      const authUserInfo = listingData.userDetails;
      try {
        const userEthBalance = await axios.get(
          `${API_NETWORK}?module=account&action=tokenbalance&contractaddress=${API_CONTRACTADDRESS}&address=${authUserInfo.receivingEthAddress}&tag=latest&apikey=APJX4Q44UGSK2EUNQYUY31YSW8UGPY2D6V`,
          {
            headers: "",
            timeout: 5000,
          }
        );

        if (userEthBalance.status === 200) {
          // TODO: logic here needs commenting!
          let finalTokenValue = Math.ceil(Number(userEthBalance.data.result));
          finalTokenValue = finalTokenValue / Math.pow(10, 18);
          listingData.ethBalance = finalTokenValue;
          listingData.decWalletAddress = authUserInfo.receivingEthAddress;
        }
      } catch (err) {
        console.log(err.message);
        toast(err.message + ": Getting DEC Balance");
      }
    }
  };

  const changeOfftakePercentage = async (e) => {
    const offtakePercentage = e.value;
    const newOfftake =
      (offtakePercentage / 100.0) * listingData.estimatedPoweCapacity;
    const powerCost = Math.ceil(
      newOfftake * listingData.offtakeDuration * listingData.powerPrice
    ); // DEC
    const powerCostUSD = powerCost * listingData.decPricePerToken; // USD
    setListingData({
      ...listingData,
      offtakePercentage,
      offtakePercentageLabel: e.label,
      newOfftake,
      powerCost,
      powerCostUSD,
    });
  };

  const changeOfftakeDuration = async (e) => {
    const offtakeDuration = e.value;
    const powerCost = Math.ceil(
      listingData.newOfftake * offtakeDuration * listingData.powerPrice
    ); // DEC
    const powerCostUSD = powerCost * listingData.decPricePerToken; // USD
    setListingData({
      ...listingData,
      offtakeDuration,
      offtakeDurationLabel: e.label,
      powerCost,
      powerCostUSD,
    });
  };

  const getPriceDEC = async () => {
    const api = new Api();
    const priceResponse = await api.get("miner/StripePrice", {
      type: "DEC",
    });
    if (priceResponse.code === 200 && priceResponse.data) {
      if (priceResponse.data.stripePrice) {
        listingData.decPricePerToken = Number(
          priceResponse.data.stripePrice.unitPrice
        );
      } else {
        listingData.decPricePerToken = Number(priceResponse.data.unitPrice);
      }
    } else {
      toast.error("Failed to get DEC price, using default $0.05");
    }
  };

  return (
    <div className={"card-container gloss-day"}>
      <div className="dark-blue-theme-color card-inner-padding dashboard-card text-center row">
        <div className="col-lg-10 pl-0 pr-0">
          <div>
            <div className="element-box">
              <h6 className="form-header flex justify-content-between">
                <span>
                  <h2>Buy Energy</h2>
                </span>
                <span>
                  <span className="mr-3">
                    <span className="mr-1 color-red text-bold">
                      Current Offtake for Supplier ID:
                    </span>
                    <span>
                      {niceNumberDecimalDisplay(
                        listingData.currentOfftake === undefined
                          ? 0
                          : listingData.currentOfftake,
                        2
                      )}{" "}
                      kWh
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <Link
                      to={{
                        // TODO: set and manage return state to /buy_energy
                        pathname: "/powersitesMarket",
                        state: {
                          returnState: listingData.returnState,
                        },
                      }}
                    >
                      <button
                        type="button"
                        className="mr-2 mb-2 btn btn-primary"
                      >
                        Back To Offtake Market
                      </button>
                    </Link>
                  </span>
                </span>
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div className="content-i">
        <div className="content-box">
          <div>
            <div className="element-box" w="25">
              <span>
                <b>Supplier ID: {listingData.supplierId}</b>
              </span>
            </div>
            <div>
              <hr />
            </div>
            <div className="element-box">
              <span>
                <b>
                  Available kWh:{" "}
                  {niceNumberDecimalDisplay(
                    listingData.estimatedPoweCapacity,
                    2
                  )}
                </b>
              </span>
            </div>
            <div>
              <hr />
            </div>
            <div className="element-box">
              <span>
                <b>
                  Price per kWh: &nbsp;{listingData.powerPrice}
                  &nbsp;DEC &nbsp;&nbsp;($
                  {niceNumberDecimalDisplay(listingData.powerPriceUSD, 4)} USD)
                </b>
              </span>
            </div>
            <div>
              <hr />
            </div>
            <div className="element-box">
              <span className="text-bold">Select Offtake Percentage</span>
              <Select
                className="w-25"
                value={{
                  value: listingData.offtakePercentage,
                  label: listingData.offtakePercentageLabel,
                }}
                options={offtakePercentageList.choices}
                placeholder="Select Off-take Percentage"
                onChange={changeOfftakePercentage}
              />
            </div>
            <div className="element-box">
              <span className="text-bold">Select Offtake Duration</span>
              <Select
                className="w-25"
                value={{
                  value: listingData.offtakeDuration,
                  label: listingData.offtakeDurationLabel,
                }}
                options={offtakeDurationList.choices}
                placeholder="Select Off-take Duration"
                onChange={changeOfftakeDuration}
                isDisabled={listingData.fixedDuration}
              />
            </div>
            <div>
              <hr />
            </div>
            <div className="element-box">
              <span>
                <b>
                  New Off-take:&nbsp;
                  {niceNumberDecimalDisplay(
                    listingData.currentOfftake === undefined
                      ? 0
                      : listingData.currentOfftake,
                    2
                  )}
                  &nbsp;+&nbsp;
                  {niceNumberDecimalDisplay(listingData.newOfftake, 2)}
                  &nbsp;=&nbsp;
                  {niceNumberDecimalDisplay(
                    (listingData.currentOfftake === undefined
                      ? 0
                      : listingData.currentOfftake) + listingData.newOfftake,
                    2
                  )}{" "}
                  kWh
                </b>
              </span>
            </div>
            <div>
              <hr />
            </div>
            <div className="element-box">
              <span>
                <b>
                  Cost: &nbsp;{Math.ceil(listingData.powerCost)}
                  &nbsp;DEC &nbsp;&nbsp;($
                  {niceNumberDecimalDisplay(listingData.powerCostUSD, 2)} USD)
                </b>
              </span>
            </div>
            <div>
              <hr />
            </div>
            <div className="element-box">
              <span>
                <b>
                  (DEC purchased will be recorded as spendable credits for this
                  supplier campaign)
                </b>
              </span>
            </div>
            <div>
              <hr />
            </div>
            <div className="element-box">
              {listingData.ethBalance <
                listingData.powerCost / listingData.decPricePerToken && (
                <span>
                  <Link
                    to={{
                      pathname: "/dec_stripe",
                      type: "DEC",
                      state: {
                        type: "DEC",
                        paymentSource: "DEC",
                        paymentReturnTarget: "/buy_energy",
                        paymentReturnState: listingData,
                        offTakeData: {
                          purposeId: listingData.listingId,
                          tokenAmount: listingData.powerCost,
                          energyAmount: listingData.newOfftake,
                          duration: listingData.offtakeDuration,
                        },
                      },
                    }}
                  >
                    <button type="button" className="mr-2 mb-2 btn btn-primary">
                      <img
                        src={decLogo}
                        alt="Buy Dec Icon"
                        height="20"
                        width="20"
                      />
                      &nbsp;Buy DEC
                    </button>
                    &nbsp;Needed to Purchase:&nbsp;
                    {Math.ceil(listingData.powerCost - listingData.ethBalance)}{" "}
                    DEC
                  </Link>
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MinerBuyEnergy;
